import * as React from "react"
import Layout from '../components/Layout/Layout';
import CenteredSection from '../components/CenteredSection/CenteredSection';
import WideImageSection from '../components/WideImageSection/WideImageSection';
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import { graphql } from 'gatsby'

import * as styles from './GenericPage.module.scss'

const GenericPage = ({pageContext, data}) => {
  const articles = data.allStrapiArticle.edges;

  const content = (node) => {
    if(!node.Title && !node.Content) return false;
    return (
      <CenteredSection>
        {node.Title && 
          <span class='bookmark' id={anchorTag(node.Title)}></span>
        }
        {node.Title && 
          <h2>{node.Title}</h2>
        }
        {node.Content &&       
          <div class={`${styles.content} ${node.ContentImagePosition && styles[node.ContentImagePosition.toLowerCase()]}`}>
            {contentImage(node)}
            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={node.Content}/>
            <div style={{clear: "both"}}></div>
          </div>
        }
      </CenteredSection>
    )
  }

  const contentImage = (node) => {
    return (
      node.ContentImage &&
      <img 
        src={node.ContentImage.localFile.childImageSharp.fluid.src} 
        alt='img'
        class={`${styles[node.ContentImageSize.toLowerCase()]}`}
      />
    )
  }

  const anchorTag = (title) => {
    const re = new RegExp("[^a-zA-Z0-9-]", "g");
    return title.replaceAll(" ","-").replaceAll(re, "").toLowerCase();
  }

  const legend = () => {
    if(!pageContext.showLegend) return false;
    if(articles[0] && articles.length < (articles[0].node.Index === 0 ? 3 : 2)) return false;

    return (
      <CenteredSection>
        <div class={styles.legend}>
          {articles.map(article => (
            article.node.Title && 
            article.node.index !== 0 &&
            <a href={`#${anchorTag(article.node.Title)}`}>{article.node.Title}<br/></a>
          ))}
        </div>
      </CenteredSection>
    )
  }

  return (
    // show legend first if no header (index>0), or after the header if header present
    <Layout pageTitle={pageContext.title}>
        {articles.map((article, i) => (
          <div>
            {i === 0 && article.node.Index > 0 && legend()} 
            {article.node.HeaderImage && <WideImageSection image={article.node.HeaderImage.localFile} heading={article.node.HeaderText}/>}
            {i === 0 && article.node.Index === 0 && legend()}
            {content(article.node)}
          </div>
        ))}
    </Layout>
  )
}

export default GenericPage;

export const pageQuery=graphql`
  query GenericPageQuery($page: String) {
    allStrapiArticle(filter: {Page: {eq: $page}} sort: {order: ASC, fields: Index}) {
      edges {
        node {
          HeaderText
          HeaderImage {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          Title
          Content
          Index
          ContentImage {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          ContentImageSize
          ContentImagePosition
        }
      }
    }
  }
`